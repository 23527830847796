import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

import "./Counter.scss";

export const Counter = ({ data }) => {
  const [countState, setCountState] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCountState(true)}
      onExit={() => setCountState(false)}
    >
      <div className="counter" id="counter">
        <div className="item item__mini-col-1 item__mini-1">
          <div>
            <img src={require("../../img/b3.png")} alt="" />
          </div>
          <div className="text">
            <div className="item__message">Взяло участь у вересні</div>
            <p className="text-number">
              {countState && (
                <CountUp start={0} end={data.input1} duration={1} delay={0} />
              )}
            </p>
            <p className="text-desc">учасників</p>
          </div>
        </div>
        <div className="item item__color item__mini-col-2 item__mini-2">
          <div>
            <img src={require("../../img/b1.png")} alt="" />
          </div>
          <div className="text">
            <div className="item__message">
              Прочитано учасниками за вересень
            </div>
            <p className="text-number">
              {countState && (
                <CountUp start={0} end={data.input2} duration={1} delay={0} />
              )}
            </p>
            <p className="text-desc">сторінок</p>
          </div>
        </div>
        <div className="item">
          <div>
            <img src={require("../../img/b4.png")} alt="" />
          </div>
          <div className="text">
            <div className="item__message">
              День, в який прочитано найбільше сторінок
            </div>
            <p className="text-number">
              {countState && (
                <CountUp start={0} end={data.input3} duration={1} delay={0} />
              )}
            </p>
            <p className="text-desc">{data.input4}</p>
          </div>
        </div>
        <div className="item item__color">
          <div>
            <img src={require("../../img/b2.png")} alt="" />
          </div>
          <div className="text">
            <div className="item__message">
              Прочитано учасниками за вересень
            </div>
            <p className="text-number">
              {countState && (
                <CountUp start={0} end={data.input5} duration={1} delay={0} />
              )}
            </p>
            <p className="text-desc">книжок</p>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
};
